.generic-card-large {
  padding: 50px;
  background-color: var(--white-color);
  border-radius: 10px;
  margin: 30px 0 20px 0;
  box-shadow: 0px 1px 3px #00000029;

  .submit-section {
    margin-top: 30px;
    align-items: center;
  }

  a.list-back-style {
    color: var(--title-color);
  }

  .align-submit-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .image-upload-section {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .user-profile-set-icon {
    color: #90959b;
    margin-right: 30px;
  }

  .user-profile-set-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 30px;
  }
  .profile-img-type-select {
    margin: 10px 0;
    font-size: 10px;
    color: var(--form-label-color);
  }

  .adjust-generate-password {
    align-items: center;

    .generate-password-button {
      margin-top: 10px;
      text-align: end;

      .addNewELD,
      .generatePassword {
        width: 90%;
        height: 50px;
        text-align: center;
        padding: 0 !important;
      }
      .generatepasswordSpace {
        padding-left: 5px;
      }
      .responsiveScreens {
        padding-left: 5px;
      }
    }
  }

  .ant-form-large .ant-form-item-label > label {
    font-size: 16px;
    color: var(--form-label-color);
    height: 100%;
    margin-left: 10px;
  }

  .ant-form-large .ant-form-item-control-input {
    min-height: 50px;
  }

  .ant-btn-primary[disabled] {
    border: 1px solid #143d73 !important;

    color: white !important;
    background: #93b9d9 !important;
  }

  .secondary-btn-md[disabled] {
    border: 1px solid #586973 !important;
    color: #586973 !important;
    background: none !important;
  }

  .ant-input-affix-wrapper-lg {
    padding: 11px 15px;
    font-size: 16px;
    border-radius: 5px;
    font-size: 16px;
    color: var(--logo-text-color);
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid #586973;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 2px rgba(88, 105, 115, 0.1);
  }

  .ant-input-affix-wrapper-status-error {
    svg {
      path {
        fill: var(--icon-color);
      }
    }
  }

  .ant-input-prefix {
    border-right: 1px solid var(--border-color);
    padding-right: 15px;
    margin-right: 15px;
    color: var(--icon-color);
  }

  textarea.ant-input {
    border-radius: 5px;
    padding: 15px 15px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 6px 15px;
    border-radius: 5px;
  }
  .ant-picker-large .ant-picker-input > input {
    padding-top: 2px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    left: 100%;
    size: 50px;
    padding-left: 3%;
    color: var(--icon-color);
  }

  .ant-form-item-explain-error {
    text-align: end;
    padding-right: 30px;
    position: relative;

    &::after {
      content: '';
      background-image: url(../resources/icons/exclamation-triangle-fill.svg);
      height: 14px;
      width: 16px;
      position: absolute;
      top: 10%;
      right: 5px;
      background-repeat: no-repeat;
    }
  }

  .checkbox-align {
    align-items: baseline;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-control-input {
      min-height: 30px;
    }
  }

  .checkPasswordWrapper {
    display: flex;
    height: 50px;

    .passwordUpdateCheck {
      margin-left: 20px !important;
    }
  }

  .hr-line {
    border-top: 1px solid #58697340 !important;
    margin: 50px 0;
  }

  span.checkbox-label-design {
    font-size: 16px;
    color: var(--form-label-color);
    margin-left: 10px;
    min-width: 200px;
  }

  .truck-icon {
    padding-bottom: 10px;
    fill: #fff !important;
  }

  .SpaceDatePicker {
    width: 100% !important;

    .datePicker {
      width: 100%;
      height: 50px;
      border-radius: 5px;
    }
  }

  .ant-select-arrow {
    color: var(--form-label-color) !important;
  }

  .addOffice {
    path:first-child {
      fill: #fff !important;
    }

    path:last-child {
      fill: rgb(43 95 140) !important;
    }
  }

  .ant-select-selection-search {
    top: 6px !important;
    left: 66px !important;
  }

  .ant-picker-input {
    margin-left: 56px;
  }

  .companyIcon {
    path:first-child {
      fill: var(--icon-color);
    }
  }

  .ant-checkbox {
    margin: 0 10px 0 0 !important;
  }

  .ant-checkbox-inner {
    border-radius: 4px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: var(--icon-color);
      border: var(--border-color);
    }
  }

  .checkbox-alignment {
    position: relative;
    top: 10px;
    right: 5px;
  }

  .selectIcons {
    position: absolute;
    z-index: 100;
    top: 15px;
    left: 16px;

    path {
      fill: var(--icon-color);
    }
  }
  .iconBorder {
    border-right: 1px solid var(--border-color);
    position: absolute;
    z-index: 100;
    left: 52px;
    height: 25px;
    top: 13px;
  }
  .ant-select-selection-placeholder {
    margin-left: 52px;
  }
  .ant-select-selection-item {
    margin-left: 51px;
  }
}
.companyContainer {
  margin: 30px 0 0 0 !important;
}

.labelSeclectAll {
  font-size: 16px !important;
  color: #43425d;
  cursor: pointer;
  span {
    margin-left: 16px;
  }
}

.selectAllCheckBox {
  accent-color: #586973 !important;
  -webkit-opacity: 0.4;
  opacity: 0.4;
  cursor: pointer;
  width: 15px !important;
  height: 15px !important;
  margin-right: 10px;

  &:checked {
    accent-color: #586973 !important;
    -webkit-opacity: 1;
    opacity: 1;
  }
}

@media (max-width: 1680px) and (min-width: 1600px) {
  .generatePassword {
    padding: 4px 30px !important;
    .responsiveScreens {
      display: block;
    }
  }
}

@media (max-width: 1600px) and (min-width: 1440px) {
  .generic-card-large {
    .submit-section {
      margin-top: 15px !important;
    }

    .ant-form-large .ant-form-item-label > label {
      font-size: 14px;
    }

    .user-profile-set-icon {
      height: 80px !important;
    }

    .ant-form-large .ant-form-item-control-input {
      min-height: 40px;
    }

    input::placeholder {
      font-size: 13px !important;
    }

    .adjust-generate-password {
      .generate-password-button {
        padding-left: 0 !important;
      }
    }

    .generatePassword {
      padding: 4px 25px 4px 25px !important;
      height: 49px !important;
      .responsiveScreens {
        display: block;
      }
    }

    .checkbox-label-design,
    .list-back-style {
      font-size: 14px !important;
    }
  }

  .content_wrapper hr {
    margin: 25px 0 !important;
  }

  .content_wrapper button.primary-btn-md,
  button.secondary-btn-md {
    font-size: 16px !important;
  }

  .custom-multi-select-dropdown .set-alighnment-dropdown .ant-col.ant-form-item-label {
    min-height: 0 !important;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1300px) {
  .generic-card-large {
    span.checkbox-label-design {
      font-size: 12px !important;
    }
  }

  .generatePassword {
    padding: 2px 14px 2px 14px !important;
    .responsiveScreens {
      display: block;
    }
  }
}
.ant-picker-time-panel-column::after {
  height: unset !important;
}
