// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  margin: 0 auto;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  --my-font-family: 'Roboto', sans-serif;
  --bg-body-color: #f0f0f5;
  --border-color: #c9ccce;
  --line-color: #c9ccce;
  --hov-notif-sel: #f2163e;
  --pg-heading-color: #122640;
  --title-color: #586973;
  --p-color: #586973;
  --icon-color: #586973;
  --white-color: #ffffff;
  --sm-card: #ffffff;
  --txt-hover-color: #122640;
  --txt-sel-color: #f2163e;
  --navbar-bg: #fff;
  --logo-text-color: #586973;
  --sidenav-color: #fff;
  --svg-color: #586973;
  --form-label-color: #43425d;
  --imput-field-focus-color: #586973;
  --sm-button-border-color: #707070;
  --sm-button-bg-color: #586973;
  --error-message-field: #f2163e;
  --checkbox-bg-color: #586973;
  --active-tab-color: #2b5f8c;
  --tab-color: #586973;
  --content-title-color: #586973;
  --content-title-bold-color: #122640;
  --custom-color-blue: #143d73;
  --light-grayish-blue: #90959b;
  --black--color: #000000;
  --pale-blue: #e2e7ee;
  --Gray85-color: #d9d9d9;
  --hrLine-color: #58697340;
  --wrapper-color: #565151;
  --Complementary-color: #00000029;
  --addOffice-color: #2b5f8c;
  --custom-color-bluish: #93b9d9;
  --custom-shadow-color: #5869731a;
  --mapInfo-color: #000000d9;
  --card-color: #fff;
  --input-color: #fff;
  --modal-color: #fff;
  --ul-text: #000000;
  --ul-body: #fff;
  --unit-tag: #fff;
  --inactive-color: #cec9c9;
  --dropdown-color: #e2e7ee;
}

[data-theme='dark'] {
  margin: 0 auto;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  --my-font-family: 'Roboto', sans-serif;
  // for background
  --bg-body-color: #262626;
  // for vertical line and for prefix line in field
  --border-color: #c9ccce;
  // unit ul test color
  --ul-text: #fff;
  // for unit ul body
  --ul-body: #3c3c3c;
  //row-inactive-color
  --inactive-color: #cec9c9;
  // for unit tag
  --unit-tag: #3c3c3c;
  // list text color
  --list-color: #ababab;
  // for when deactivate apply then apply on text color
  --line-color: #262626;
  // for nav bar hover and color
  --hov-notif-sel: #f2163e;
  // pg unit card heading text || and for nav bar menu text
  --pg-heading-color: #8d8e91;
  // for form return listing text || for search box text  || for range calender in listing text
  --title-color: #586973;
  // for text field background color
  --field-color: #3c3c3c;
  // for unit location color
  --p-color: #dbdbdb;
  // for all prefit icons color
  --icon-color: #ababab;
  // and listing add icon and when on hover
  --white-color: #fff;
  // for all card background color
  --card-color: #2d2d2d;
  // for unit small card color
  --sm-card: #2d2d2d;
  //  navbar  tooltip text
  --tooltip-color: #ababab;
  --name-color: #ababab;
  // and for all listing action column text color
  --txt-hover-color: #ababab;
  // could'nt find this
  --txt-sel-color: #f2163e;
  // for header background
  --navbar-bg: #2d2d2d;
  // for footer text
  --logo-text-color: #ababab;
  // header text color
  --header-color: #ababab;
  // for sidebar background color
  --sidenav-color: #2d2d2d;
  // for sidebar svg color
  --svg-color: #ababab;
  // for all form labels and arrow dropdown button
  --form-label-color: #ababab;
  // could not find this
  --imput-field-focus-color: #586973;
  // could not find this
  --sm-button-border-color: #707070;
  // could not find this
  --sm-button-bg-color: #586973;
  // could not find this
  --error-message-field: #f2163e;
  // could not find this
  --checkbox-bg-color: #586973;
  // for all submit button|| and active tab in unit
  --active-tab-color: #2b5f8c;
  // for unactive tab
  --tab-color: #e00000;
  // just inn pagination total showing page
  --content-title-color: #586973;
  // for in unit and log table event table list test color
  --content-title-bold-color: #ffffff;
  // for all form button on hover
  --custom-color-blue: #143d73;
  // for user profile icon
  --light-grayish-blue: #90959b;
  // couldnt find this
  --black--color: #000000;
  // for listing header|| action buton hover
  --pale-blue: #191919;
  // dropdown color on hover
  --dropdown-color: #323232;
  // couldnt find this
  --Gray85-color: #d9d9d9;
  // couldnt find this
  --hrLine-color: #58697340;
  // couldnt find this but on code is class use in error screen
  --wrapper-color: #565151;
  // couldnt find this
  --Complementary-color: #00000029;
  // couldnt find this
  --addOffice-color: #2b5f8c;
  // pagination text color
  --page-text: #ababab;
  // for all modal popup
  --modal-color: #2d2d2d;
  --custom-color-bluish: #93b9d9;
  --custom-shadow-color: #5869731a;
  --mapInfo-color: #000000d9;
  --input-color: #3c3c3c;
}

// basic styles
.centered-align {
  text-align: center;
}
.green {
  color: #03ab2c;
}
.red {
  color: #ee0202;
}
.fw-500 {
  font-weight: 500;
}
.mb-25 {
  margin-bottom: 25px;
}
.display-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
// .ant-slider-tooltip {
//   top: 765px !important;
//   z-index: 0;
//   .ant-tooltip-arrow {
//     display: none;
//   }
//   .ant-tooltip-inner {
//     background: transparent;
//     box-shadow: none;
//     color: #2b5f8c;
//     font-weight: bold;
//     padding: 0;
//   }
// }
