.TransferPicker {
  top: 260px !important;
  position: fixed;

  .ant-popover-content {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .ant-popover-content .ant-popover-inner {
    background-color: #fff !important;
  }
  .ant-popover-content .ant-popover-arrow .ant-popover-arrow-content::before {
    background: #fff !important;
  }
  .ant-popover-content .ant-popover-inner .ant-popover-title {
    display: none;
  }
  .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    display: block !important;
  }

  .time-inputs {
    display: flex;
    align-items: center;
    .handle {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .handle input {
      text-align: center;
      width: 50px;
      border-radius: 5px;
      padding: 4px;
      border-color: #5869734a;
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;