    .custom_info_design_current-location-history{
        overflow: hidden;
        max-width: 270px;

        ul.info_list {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                margin-bottom: 5px;
            }
        }

        p {
            font-weight: bold;
            margin: 0;
        }
    }
@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;