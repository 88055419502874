@import '../styles/base.less';
@import '../styles/table.less';
@import '../styles/form.less';
@import '../styles/common.less';

.content_wrapper {
  padding: 0 30px;
  position: relative;
  background: var(--bg-body-color);
  top: 94px;
  z-index: 0;

  &.sidebar-expand-content {
    margin-left: 250px;
  }

  &.sidebar-close-content {
    margin-left: 74px;
  }

  .footer {
    .footer-content {
      display: flex;
      justify-content: space-between;

      .footer-text {
        font-size: 12px;
        color: var(--logo-text-color);
      }
    }
  }

  .generic-card-small {
    padding: 20px;
    margin: 20px 0 20px 0;
    background-color: var(--sm-card);
    border-radius: 10px;
    box-shadow: 0px 1px 3px #00000029;

    &.card-1 {
      min-height: 330px;
    }
  }

  .pg-heading {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    color: var(--pg-heading-color);
  }

  .content-title {
    font-weight: bold;
    font-size: 16px;
    color: var(--content-title-color);
  }

  .content-title-bold {
    font-weight: bold;
    font-size: 16px;
    color: var(--content-title-bold-color);
  }

  a:active {
    color: var(--active-link-color);
  }

  a:hover {
    color: var(--hover-link-color);
  }

  p.lg-body {
    color: var(--p-color);
    font-size: 18px;
  }

  p.p-body {
    color: var(--p-color);
    font-size: 16px;
  }

  p.sm-body {
    color: var(--p-color);
    font-size: 14px;
  }

  p.notify-text {
    font-size: 14px;
    color: var(--txt-sel-color);
  }

  a.list-back-style {
    color: var(--title-color);
    display: flex;
    align-items: center;
    font-size: 18px;
    text-decoration: underline;

    svg {
      margin-right: 10px;
    }
  }

  button.primary-btn-sm {
    font-size: 14px;
    padding: 10px 20px;
    text-transform: capitalize;
    border: 1px solid var(--sm-button-border-color);
    background-color: var(--sm-button-bg-color);
  }

  button.secondary-btn-sm {
    font-size: 14px;
    padding: 10px 20px;
    text-transform: capitalize;
    color: var(--sm-button-border-color);
    border: 1px solid var(--sm-button-border-color);
    background: transparent;
  }

  button.tertiary-btn-sm {
    font-size: 14px;
    padding: 12px 20px;
    color: var(--active-tab-color);
    text-transform: capitalize;
    border: 1px solid var(--active-tab-color);
    background: transparent;
    height: auto;
    border-radius: 5px;
  }

  button.tertiary-btn-sm:hover {
    color: var(--white-color);
    background: var(--custom-color-blue);
  }

  button.primary-btn-md {
    background: var(--active-tab-color);
    border: 1px solid var(--custom-color-blue);
    color: #fff;
    outline: none;
    min-width: 240px;
    min-height: 50px;
    height: 100%;
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'roboto';

    svg {
      margin-right: 10px;
      vertical-align: text-top;
    }
  }

  button.primary-btn-md:hover {
    border: 1px solid var(--custom-color-blue);
    background: var(--custom-color-blue);
  }

  button.secondary-btn-md {
    background: transparent;
    border: 1px solid var(--title-color);
    outline: none;
    min-width: 240px;
    min-height: 50px;
    height: 100%;
    color: var(--title-color);
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'roboto';
  }

  button.secondary-btn-md:hover {
    border: 1px solid var(--logo-text-color);
    color: var(--logo-text-color);
  }

  .button-margin-right {
    margin-right: 20px !important;
  }

  .button-margin-left {
    margin-left: 20px !important;
  }
  button.primary-btn-md,
  button.secondary-btn-md {
    border-radius: 4px;
  }
  hr {
    background-color: var(--border-color);
    opacity: 0.3 !important;
    margin: 50px 0;

    &.card-small-margin {
      margin: 15px 0;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 40px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #858585;
    border-radius: 10px;
  }
}

.childrenWraaper {
  min-height: 788px;
}

.ant-breadcrumb ol {
  align-items: baseline !important;
}

@media (max-width: 1600px) {
  .childrenWraaper {
    min-height: 700px !important;
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;