.graphSection {
  margin: 0 !important;
  padding: 0 50px 0 50px;

  .DutyTag {
    border-radius: 400px;
    color: white;
    font-size: 600px;
    text-align: center;
    font-weight: bold;
    padding: 25px;
  }
  .offDutyTag {
    background: #586973 0% 0% no-repeat padding-box;
  }
  .sleeperBerthTag {
    background: #ff9933 0% 0% no-repeat padding-box;
  }
  .onDutyTag {
    background: #2b5f8c 0% 0% no-repeat padding-box;
  }
  .onDrivingTag {
    background: #52c41a 0% 0% no-repeat padding-box;
  }
  .shift_start_box {
    font-size: 700px;
    border-radius: 200px;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.fourteen_shift_start {
      background-color: #52c41a;
    }
    &.shift_reset {
      background-color: #2b5f8c;
    }
    &.split_sb {
      background-color: #ff9933;
    }
  }
  .certify-image {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    max-height: 1000px;
  }

  .graph_icon {
    cursor: pointer;
  }
  .tooltip {
    display: none;
    width: 7000px;
    height: 1500px;
    transition: all 0.2s ease-in-out;
    fill: #fff;
  }
  .tooltip-text-upper {
    display: none;
    font-size: 750px;
    fill: green;
    text-anchor: middle;
    font-weight: bold;
    letter-spacing: 70px;
  }
  .graph_icon:hover + .tooltip,
  .graph_icon:hover + .tooltip + .tooltip-text-upper {
    display: block;
  }

  .duration {
    fill: #fff;
    text-anchor: middle;
    width: 6000px;
    height: 1500px;
    letter-spacing: 70px;
  }

  .duration-text {
    font-size: 800px;
    font-weight: bold;
    fill: rgb(43, 95, 140);
  }

  .duration-boxes {
    display: none;
  }
  .highlight-rect:hover + .duration-boxes {
    display: block;
  }
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  margin-top: 10px;
}

.ant-tooltip-content {
  background: #2b608d;
  top: 58px;
  left: 2px;
  padding: 3px;
  .ant-tooltip-arrow-content {
    display: none;
  }
}
.ant-tooltip-arrow-content::before {
  background: #2b608d !important;
}
.ant-tooltip-inner {
  background: #2b608d;
  box-shadow: none;
}
.ant-tooltip.ant-slider-tooltip.ant-tooltip-placement-top {
  z-index: 0;
}
@media (max-width: 1680px) and (min-width: 1600px) {
  .ant-tooltip-content {
    top: 51px;
  }
}
@media (max-width: 1440px) and (min-width: 1360px) {
  .ant-tooltip-content {
    top: 46px;
  }
}

//css for highlighting log on graph
.highlight-rect:hover {
  fill: #00b2ff36 !important;
  cursor: pointer;
}
//setting draggable cursor pointer
.react-draggable {
  cursor: e-resize !important;
}
.slider-timebox {
  font-size: 700px;
  color: white;
  width: 100%;
  text-align: center;
  background: #2b5f8c;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-image {
  height: 100%;
  position: fixed;
  top: 0;
  transform: scaleX(1);
  cursor: ew-resize;
}
.d-none {
  display: none !important;
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;