.Wrapper {
  padding-top: 90px;

  .ServerErrorContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .ErrorIcon {
      background: url('../../../resources/icons/404Error.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      min-height: 400px;
      height: 100%;
    }
  }

  .ErrorMessage {
    text-align: center;
    margin-top: 70px;

    .errorHeading {
      text-align: center;
      // font: normal normal normal 40px/11px Roboto;
      font-size: 40px;
      letter-spacing: 0px;
      color: #f2163e;
      height: 10px;
    }
    .errorText {
      text-align: center;
      // font: normal normal normal 40px/48px Roboto;
      font-size: 40px;
      letter-spacing: 0px;
      color: #586973;
    }
  }
  .Routes {
    text-align: center;

    .ptag {
      height: 10px;
    }
    .pathRoute-text {
      font-size: 16px;
      color: #586973;
    }
    .pathLink,
    .prevLink {
      text-decoration: underline;
      color: #2b5f8c;
      margin-left: 5px;
    }
    .prevLink {
      cursor: pointer;
    }
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;