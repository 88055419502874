.InspectionReport {
  .pg-heading {
    text-transform: capitalize !important;
  }
  .columnBg,
  .defectColumn,
  .noDefectsColum {
    // background-color: #f0f0f5;

    // padding: 20px;

    .colHeading {
      font-size: 16px;
      font-weight: bold;
      color: #586973;
      margin-left: 20px;
    }
    .colHeading2 {
      font-size: 16px;
      font-weight: bold;
      color: #586973;
    }
    .hr-line {
      border-top: 1px solid #58697340;
      padding-bottom: 15px;
    }

    .defectInfo {
      padding: 0 0 0 20px;
      .defectType {
        text-transform: capitalize;
        font-size: 14px;
        font-weight: bold;
        margin-right: 15px;
        margin-left: 5px;
      }
      .DefectBadge {
        border-radius: 5px;
        width: 66px;
        height: 23px;

        text-align: center;
      }
      .major {
        border: 1px solid #f2163e;
        color: #f2163e;
        text-transform: capitalize;
      }
      .minor {
        border: 1px solid #122640;
        color: #122640;
        text-transform: capitalize;
      }
      .notes {
        padding-top: 10px;
        color: #586973;
      }
    }
    .defectImg {
      text-align: end;
      padding: 0 20px 0 0;
    }
    .noDataContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .noDefectsOverAll {
      min-height: 100px;
    }
    .defectFound {
      min-height: 100%;
    }
  }
  .defectColumn {
    min-height: 100%;
  }
  .noDefectsColum {
    min-height: auto !important;
  }
}

.driverSignature {
  min-height: 215px;

  .pg-heading {
    text-transform: capitalize !important;
  }
  .hr-line {
    border-top: 1px solid #58697340;
    padding-bottom: 15px;
  }
  .signatureText {
    position: absolute;
    top: 50%;
    font-size: 16px;
    color: #122640;
  }
  .defectRepairStatus {
    width: 838px;
    height: 50px;
    background-color: #f2163e26;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .errorRepairStatus {
    background: #f2163e26 0% 0% no-repeat padding-box;
    .defectText {
      color: #f2163e;
      font-size: 18px;
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .resolveRepairStatus {
    background: #28a74526 0% 0% no-repeat padding-box;
    .defectText {
      color: #28a745;
      font-size: 18px;
      font-weight: bold;
      margin-left: 10px;
    }
  }

  .mechanicSignature {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      color: #f2163e;
    }
  }
  .singnatureImage {
    margin-top: -40px;
    margin-left: 579px;
  }
  .singnatureImage2 {
    margin-top: -74px;
    margin-left: 885px;
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;