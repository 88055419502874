.login-form-wrapper {
  top: 0px;
  left: 0px;
  background: url('../../../../src/resources/images/login-bg.jpg');
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .form-container {
    height: 100vh;
    .blur_bg {
      position: relative;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .LoginForm {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.5);
        width: 588px;
        height: 662px;
        box-shadow: 0px 3px 25px #00000029;
        opacity: 1 !important;
        backdrop-filter: blur(45px);
        padding: 0 3rem !important;
        .loginForm {
          padding: 0 30px;
        }
        .form-label label {
          font-size: 16px;
          color: var(--form-label-color);
        }
        .form-control {
          border: 1px solid #586973;
          height: 50px;
          width: 100%;
          border-radius: 4px;
          background: #ffffff 0% 0% no-repeat padding-box;
          font-size: 16px;
          color: var(--logo-text-color);
        }
        .ant-input {
          padding-left: 10px;
        }
        .ant-input-prefix {
          border-right: 1px solid #c9ccce;
          margin: 9px 0;
          padding-right: 10px;
        }
        .border {
          border-left: 1px solid black;
        }
        input.form-control:focus {
          box-shadow: none;
          border-color: var(--imput-field-focus-color);
        }
        .ant-form-item input[type='checkbox'] {
          width: 18px;
          height: 18px;
          accent-color: #586973;
        }
        .checkbox-reminder {
          padding-left: 1rem;
        }
        .ant-form label {
          font-size: 16px;
        }
        .comapny_logo {
          display: flex;
          justify-content: center;
          padding-right: 1rem !important;
          padding-bottom: 20px;
          .img-fluid {
            padding-bottom: 0.5rem !important;
            max-width: 100%;
            height: auto;
          }
        }
        .formHeading {
          text-align: center;
          font: normal normal bold 24px/28px Roboto;
          letter-spacing: 0px;
          color: #122640;
          text-decoration: none !important;
        }

        .ConfirmationSuccessModalIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 55px;
          margin-top: 30px;
        }

        .changedPassword {
          margin-bottom: 0px !important;
        }

        .forgotPasswordInfoText {
          font-size: 18px;
          color: #586973;
          opacity: 1;
          text-align: center;
          letter-spacing: var(--unnamed-character-spacing-0);
          margin-bottom: 30px;
          padding: 0 20px;
        }

        .detailParagraph {
          padding: 0 20px;
        }

        .forgotButton {
          margin-top: 20px !important;
          margin-bottom: 20px !important;
        }

        .loginReturn {
          margin-top: 20px;
          p {
            text-align: center;
            font-size: 18px;
            span {
              margin-left: 10px;
              text-decoration: underline;
            }
          }
        }

        .welcome-text,
        .invalidLoginText {
          padding-bottom: 20px;
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
            var(--unnamed-line-spacing-13) var(--unnamed-font-family-roboto);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-586973);
          text-align: center;
          font: normal normal normal 18px/13px Roboto;
          letter-spacing: 0px;
          color: var(--title-color);
          opacity: 1;
        }

        .LoginFailedText {
          font-weight: bold;
          margin-left: 15px;
        }

        .wrapperInavlidLogin {
          width: 435px !important;
          margin-left: 30px;
          border: 1px solid #f8d7da;
          background-color: #f8d7da;
          margin-bottom: 20px;
          border-radius: 4px;

          .invalidLoginText {
            position: relative;
            top: 20px;
            color: red !important;
            .errorIcon {
              margin-bottom: 18px;
            }
          }
        }

        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          visibility: hidden;
        }
        .checkbox-text {
          padding-left: 10px;
          color: #586973;
        }
        span.input-with-icon {
          position: absolute;
          top: 14px;
          left: 15px;
          border-right: 1px solid var(--border-color) !important;
          padding-right: 15px;
          z-index: 1;
          svg {
            color: var(--icon-color);
          }
        }
        .input-with-icon-two {
          position: absolute;
          top: 15px;
          right: 15px;
          padding-right: 5px;
          cursor: pointer;
          svg {
            color: var(--icon-color);
          }
        }

        .custom-field-2 {
          position: relative;
          margin-bottom: 30px;
          max-height: 80px;
          span.input-with-icon {
            position: absolute;
            top: 45px;
            left: 15px;
            border-right: 1px solid var(--border-color) !important;
            padding-right: 15px;
            svg {
              color: var(--icon-color);
            }
          }
          .input-with-icon-two {
            position: absolute;
            top: 45px;
            right: 15px;
            padding-right: 5px;
            cursor: pointer;
            svg {
              color: var(--icon-color);
            }
          }
          .form-control {
            border: 1px solid var(--title-color);
            height: 50px;
            width: 100%;
            border-radius: 4px;
            padding: 12px 15px 12px 60px;
            font-size: 16px;
            color: var(--logo-text-color);
          }
          input.form-control:focus {
            box-shadow: none;
            border-color: var(--imput-field-focus-color);
          }
          .error-message-field p {
            font-size: 14px;
            margin: 0;
            color: var(--error-message-field);
          }
          .error-message-field svg {
            color: var(--error-message-field);
            margin: 0 10px;
          }
        }
      }
    }
  }
  .ant-form-item-explain-error {
    text-align: end !important;
  }
  .forget_Sigin_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .checkbox-container {
      display: flex;
      justify-content: stretch;
      align-items: center;
    }
    .checkbox {
      width: 18px;
      height: 18px;
    }
    .forgetPassword {
      a {
        color: var(--title-color);
        text-decoration-color: var(--title-color);
      }
    }
  }

  .login-btn {
    width: 100%;
    height: 50px;
    border: 1px solid var(--unnamed-color-143d73);
    background: var(--active-tab-color);
    border: 1px solid var(--custom-color-blue);
    margin: 30px 0 0 0;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 4px !important;
  }

  .ant-btn-primary[disabled] {
    border: 1px solid var(--custom-color-blue) !important;
    background: var(--active-tab-color) !important;
    color: white !important;
  }

  .ant-checkbox-input {
    opacity: inherit;
  }
  .text-right {
    text-align: right;
    text-decoration: underline;
  }
  .forgotPassword {
    text-decoration: underline;
  }
  .loginicon {
    position: absolute;
    right: 235px;
  }
  .register-redirect {
    position: relative;
    top: 2%;
    text-decoration: underline;
    .register-text {
      text-align: center;
      font: normal normal normal 15px/16px Roboto;
    }
  }
  .terms_policy {
    position: relative;
    top: 8%;
    .termPolicy-text {
      text-align: center;
      font: normal normal normal 12px/13px Roboto;
    }
  }
  .login-btn {
    width: 100%;
    height: 50px;
    border: 1px solid var(--unnamed-color-143d73);
    background: var(--active-tab-color);
    border: 1px solid var(--custom-color-blue);
    margin: 30px 0 0 0;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 10px;
  }
  .ant-form-item-control-input-content {
    svg {
      path {
        fill: var(--icon-color);
      }
    }
  }
}

@media (max-width: 1600px) {
  .login-form-wrapper .form-container .blur_bg .LoginForm {
    width: 490px !important;
    height: 520px !important;
    .form-control,
    .form-label label,
    .ant-form label {
      font-size: 15px !important;
    }
    .form-control {
      height: 50px;
    }
    .welcome-text {
      padding-bottom: 15px;
    }
    .login-btn {
      margin: 10px 0 0 0 !important;
      font-size: 15px;
    }
    .wrapperInavlidLogin {
      width: 340px !important;
      margin-left: 30px;
      .invalidLoginText {
        font: normal normal normal 16px/13px Roboto !important;
      }
    }
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;