.side-nav {
  height: calc(100vh - 70px);
  position: fixed;
  top: 74px;
  box-shadow: 1px 2px 3px #00000029;
  z-index: 999;
  background-color: var(--sidenav-color);
  transition: all 0.2s;

  .tooltip {
    position: relative !important;
    display: inline-block;
    opacity: 1 !important;

    .tooltiptext {
      visibility: hidden;
      width: 90px;
      background-color: black;
      color: var(--white-color);
      text-align: center;
      border-radius: 6px;
      padding: 2px 2px;
      margin-left: 30px;
      font-size: 14px !important;

      /* Position the tooltip */
      position: absolute;
      z-index: 1;

      .triangle-left {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 10px solid black;
        margin-left: -10px;
        border-bottom: 5px solid transparent;
        margin-top: 8px;
        position: absolute;
      }
    }

    &:hover .tooltiptext {
      visibility: visible;
      display: block !important;
      top: -5px;
      left: 42%;
    }
  }

  svg {
    path {
      fill: var(--svg-color);
    }
  }

  &.sidebar-close {
    width: 74px;
  }

  &.sidebar-expand {
    width: 250px;
  }

  .sidebar-group {
    list-style-type: none;
    padding: 0;
  }

  .sidebar-group-emelemnt {
    padding: 0;
  }

  .group-items {
    list-style-type: none;
    padding: 0;
  }

  .item {
    margin: 20px 0px 0px 20px;
  }

  .item:hover {
    border-right: 3px solid var(--hov-notif-sel);
  }

  .active-item {
    border-right: 3px solid var(--hov-notif-sel);

    a {
      color: var(--hov-notif-sel);
    }

    svg path,
    svg circle {
      fill: var(--hov-notif-sel) !important;
    }
  }

  .item:hover a {
    color: var(--hov-notif-sel);
  }

  .item svg path,
  .item svg circle {
    fill: var(--svg-color);
  }

  .item:hover svg path,
  .item:hover svg circle {
    fill: var(--hov-notif-sel);
  }

  .item span {
    font-size: 16px;
    margin-left: 20px;
  }

  .item div.left_nav_icons {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 34px;
  }

  .group_divider.setting_line {
    border-bottom: 1px solid var(--border-color);
    margin-left: 20px;
    margin-right: 20px;
    width: 34px;
  }

  li .item a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--pg-heading-color);
  }
}

.sidebar-close .sidebar-group .sidebar-group-emelemnt .group-items .item span {
  display: none;
}

.side-nav.sidebar-expand .group_divider {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: var(--pg-heading-color);
  margin-left: 20px;
}

.side-nav.sidebar-close .group_divider {
  margin-top: 20px;
  text-align: center;
}
@media (max-width: 1400px) {
  .left_nav_icons {
    svg {
      width: 25px;
      height: 19px;
    }
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;