.app-modal {
  //   background-color: var(--white-color);
  //   border-radius: 10px;
  //   box-shadow: 0px 1px 3px #00000029;
  p.justify-text {
    font-size: 14px;
    text-align: justify;
  }
  .ant-modal-content {
    border-radius: 10px;
    border: 1px solid var(--border-color);

    .ant-btn {
      font-size: 16px;
      padding: 7px 30px;
      height: 100%;
      border-radius: 5px;
      min-width: 137px;
    }
    .ant-btn-default {
      color: var(--title-color);
      background-color: transparent;
      border: 1px solid var(--title-color);
      text-transform: uppercase;

      &:hover {
        color: var(--logo-text-color);
        border: 1px solid var(--logo-text-color);
      }
    }
    .ant-btn-primary {
      color: var(--white-color);
      text-transform: uppercase;
      border: 1px solid var(--custom-color-blue);
      background-color: var(--active-tab-color);

      &:hover {
        border: 1px solid var(--custom-color-blue);
        background: var(--custom-color-blue);
        color: var(--white-color);
      }
    }
  }
  .ant-modal-header {
    padding: 20px 0;
    margin: 0 20px;
  }
  .ant-modal-body {
    min-height: 90px;
    height: 100%;
    padding: 20px;

    p.modal-body-title {
      color: var(--p-color);
      font-size: 16px;

      .modal-body-strong {
        color: var(--p-color);
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .ant-modal-footer {
    padding: 20px 0;
    margin: 0 20px;
  }
  .ant-modal-title {
    text-transform: capitalize;
    font-weight: bold !important;
    font-size: 18px !important;
    color: var(--pg-heading-color);
  }
  .submit-section {
    margin-top: 30px;
    align-items: center;
  }

  a.list-back-style {
    color: var(--title-color);
  }

  .align-submit-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .ant-form-large .ant-form-item-label > label {
    font-size: 16px;
    color: var(--form-label-color);
    height: 100%;
    margin-left: 10px !important;
  }

  .form-label {
    color: #43425d;
    font: normal normal normal 16px/25px Roboto;
  }

  .ant-form-large .ant-form-item-control-input {
    min-height: 50px;
  }
  .SpaceTimePicker {
    width: 100% !important;

    .timePicker {
      width: 100%;
      height: 50px;
      border-radius: 5px;
    }
    .datePicker {
      width: 100%;
      height: 50px;
      border-radius: 5px;
    }
  }

  .SpaceDatePicker {
    .datePicker {
      width: 100%;
      height: 50px;
      border-radius: 5px;
    }
    .ant-picker-large .ant-picker-input > input {
      font-size: 16px !important;
      margin-left: 0px !important;
    }
  }

  .logsRangePicker {
    .ant-picker-input {
      margin-left: 29px !important;
    }
    .ant-space-item {
      .ant-picker-range {
        grid-gap: 0 !important;
        grid-gap: 0 !important;
        gap: 0 !important;
        width: 100%;
        height: 50px;
        border-radius: 6px;
        padding-left: 30px;

        .ant-picker-active-bar {
          margin-left: 52px;
          width: 80px !important;
        }
      }
      .ant-picker-active-bar {
        margin-left: 68px !important;
        width: 80px !important;
      }
    }
  }

  .ant-btn-primary[disabled] {
    border: 1px solid #143d73 !important;

    color: white !important;
    background: #93b9d9 !important;
  }

  .secondary-btn-md[disabled] {
    border: 1px solid #586973 !important;
    color: #586973 !important;
    background: none !important;
  }

  .ant-input-affix-wrapper-lg {
    padding: 11px 15px;
    font-size: 16px;
    border-radius: 5px;
    font-size: 16px;
    color: var(--logo-text-color);
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid #586973;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 2px rgba(88, 105, 115, 0.1);
  }

  .ant-input-affix-wrapper-status-error {
    svg {
      path {
        fill: var(--icon-color);
      }
    }
  }

  .ant-input-prefix {
    border-right: 1px solid var(--border-color);
    padding-right: 15px;
    margin-right: 15px;
    color: var(--icon-color);
  }

  .ant-checkbox + span {
    margin-left: 8px !important;
  }

  textarea.ant-input {
    border-radius: 5px;
    padding: 15px 15px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 6px 15px;
    border-radius: 5px;
  }
  .ant-picker-large .ant-picker-input > input {
    padding-top: 2px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    left: 100%;
    size: 50px;
    padding-left: 3%;
    color: var(--icon-color);
  }

  .ant-form-item-explain-error {
    text-align: end;
    padding-right: 30px;
    position: relative;

    &::after {
      content: '';
      background-image: url(../resources/icons/exclamation-triangle-fill.svg);
      height: 14px;
      width: 16px;
      position: absolute;
      top: 10%;
      right: 5px;
      background-repeat: no-repeat;
    }
  }

  .checkbox-align {
    align-items: baseline;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-control-input {
      min-height: 30px;
    }
  }

  .checkPasswordWrapper {
    display: flex;
    height: 50px;

    .passwordUpdateCheck {
      margin-left: 20px !important;
    }
  }

  .hr-line {
    border-top: 1px solid #58697340 !important;
    margin: 50px 0;
  }

  span.checkbox-label-design {
    font-size: 16px;
    color: var(--form-label-color);
    margin-left: 10px;
    min-width: 200px;
  }

  .ant-select-arrow {
    color: var(--form-label-color) !important;
  }

  .ant-select-selection-search {
    top: 0px !important;
    left: 0px !important;
  }

  .ant-picker-input {
    margin-left: 56px;
  }

  .ant-checkbox {
    margin: 0 10px 0 0 !important;
  }

  .ant-checkbox-inner {
    border-radius: 4px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: var(--icon-color);
      border: var(--border-color);
    }
  }

  .checkbox-alignment {
    position: relative;
    top: 10px;
    right: 5px;
  }

  .selectIcons {
    position: absolute;
    z-index: 100;
    top: 15px;
    left: 16px;

    path {
      fill: var(--icon-color);
    }
  }
  .iconBorder {
    border-right: 1px solid var(--border-color);
    position: absolute;
    z-index: 100;
    left: 52px;
    height: 25px;
    top: 13px;
  }
  .ant-select-selection-placeholder {
    margin-left: 52px;
  }
  .ant-select-selection-item {
    margin-left: 51px;
  }

  .companyContainer {
    margin: 30px 0 0 0 !important;
  }

  .labelSeclectAll {
    font-size: 16px !important;
    color: #43425d;
    cursor: pointer;
    span {
      margin-left: 16px;
    }
  }

  .selectAllCheckBox {
    accent-color: #586973 !important;
    -webkit-opacity: 0.4;
    opacity: 0.4;
    cursor: pointer;
    width: 15px !important;
    height: 15px !important;
    margin-right: 10px;

    &:checked {
      accent-color: #586973 !important;
      -webkit-opacity: 1;
      opacity: 1;
    }
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-multiple.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 6px 15px;
    border-radius: 5px;
    min-height: 50px;

    .ant-select-selection-placeholder {
      margin-left: 52px;
    }
  }
  .ant-select-selector .ant-select-selection-search-input {
    margin-left: 65px !important;
    margin-top: 5px !important;
  }
  .ant-select-multiple {
    .ant-select-selector {
      .ant-select-selection-overflow {
        margin-left: 40px;
        .ant-select-selection-overflow-item {
          .ant-select-selection-search {
            left: 0;

            input {
              width: 93%;
            }
          }
          .ant-select-selection-item {
            margin-left: 0;
          }
        }
      }
    }
  }

  .ant-radio-group {
    padding: 5px;
  }

  .custom-csv-file-footer {
    text-align: end;

    .cancel-button-set {
      margin-right: 10px;
    }
    .generic-button-class {
      font-size: 16px;
      padding: 7px 30px;
      height: 100%;
      border-radius: 5px;
      min-width: 137px;
    }
    .ant-btn-default {
      color: var(--title-color);
      background-color: transparent;
      border: 1px solid var(--title-color);
      text-transform: uppercase;

      &:hover {
        color: var(--logo-text-color);
        border: 1px solid var(--logo-text-color);
      }
    }
    .ant-btn-primary {
      color: var(--white-color);
      text-transform: uppercase;
      border: 1px solid var(--custom-color-blue);
      background-color: var(--active-tab-color);

      &:hover {
        background-color: var(--custom-color-blue);
        color: var(--white-color);
      }
    }
  }

  @media (max-width: 1600px) and (min-width: 1440px) {
    .submit-section {
      margin-top: 15px !important;
    }

    .ant-form-large .ant-form-item-label > label {
      font-size: 14px;
    }

    .ant-form-large .ant-form-item-control-input {
      min-height: 40px;
    }

    .ant-form-item-control {
      margin-bottom: 10px;
    }

    input::placeholder {
      font-size: 13px !important;
    }

    .checkbox-label-design,
    .list-back-style {
      font-size: 14px !important;
    }

    .content_wrapper hr {
      margin: 25px 0 !important;
    }

    .content_wrapper button.primary-btn-md,
    button.secondary-btn-md {
      font-size: 16px !important;
    }

    .custom-multi-select-dropdown .set-alighnment-dropdown .ant-col.ant-form-item-label {
      min-height: 0 !important;
    }
  }

  .ant-picker-time-panel-column::after {
    height: unset !important;
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;