
  .table-container.editedHistoryTable {
    margin: 0%;
    padding: 0%;
    box-shadow: none;

    .ant-table-content {
      height: 100px;
      overflow: auto;
    }
  }

  .icon-color-viewIcon {
    cursor: pointer;
    fill: #586973;
    align-items: center;
  }
  .history-modal{
    .graphSection{
      padding: 0px !important;
      svg{

        height: 150px !important;
      }
    }
  }
@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;