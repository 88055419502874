.inspectionDownload {
  border: 1px solid #586973 !important;
  color: #586973 !important;

  svg {
    path {
      fill: #586973 !important;
    }
  }
  &:hover {
    background-color: white !important;
    border: 1px solid #586973 !important;
    color: #586973 !important;

    svg {
      path {
        fill: #586973 !important;
      }
    }
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;