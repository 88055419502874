.ant-layout-header {
  position: fixed;
  z-index: 999;
  box-shadow: 1px 1px 3px #00000029;
  background-color: var(--navbar-bg) !important;
  width: 100%;
  padding: 0 !important;
  height: auto !important;
}

.navbar {
  display: flex;
  align-items: center;
  min-height: 74px;
  height: 100%;
  color: white;
  font-family: Helvetica;
  font-weight: 300;

  .navbar__title {
    margin: 0 10px;
  }

  .toprow {
    width: 100%;
    align-items: center;

    .company-logo {
      // width: 40px;
      // height: 40px;
      border-radius: 50%;

      // img {
      //   vertical-align: unset;
      // }
    }

    .navMenu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    // .ant-popover {
    //   top: 50px !important;

    //   .ant-popover-content{
    //     .ant-popover-arrow{
    //       .ant-popover-arrow-content{
    //         &::before{
    //           background: black !important;
    //         }
    //       }
    //     }

    //     .ant-popover-inner{
    //       background-color: black !important;
    //       border-radius: 3px !important;

    //       .ant-popover-title{
    //         min-width: 90px !important;
    //         min-height: 0px !important;
    //         color: white !important;
    //       }
    //     }
    //   }
    // }

    // .tooltip {
    //   position: relative !important;
    //   display: inline-block;
    //   opacity: 1 !important;

    //   .triangle-left {
    //     width: 0;
    //     height: 0;
    //     border-top: 5px solid transparent;
    //     border-right: 10px solid black;
    //     margin-left: -10px;
    //     border-bottom: 5px solid transparent;
    //     margin-top: 8px;
    //     position: absolute;
    //   }

    //   .tooltiptext {
    //     visibility: hidden;
    //     width: 120px;
    //     background-color: black;
    //     color: var(--white-color);
    //     text-align: center;
    //     border-radius: 6px;
    //     padding: 2px 2px;
    //     margin-left: 30px;

    //     /* Position the tooltip */
    //     position: absolute;
    //     z-index: 1;
    //   }

    //   &:hover .tooltiptext {
    //     visibility: visible;
    //     display: block !important;
    //     top: -5px;
    //     left: 42%;
    //   }
    // }
  }
}

.menu {
  width: auto;
  padding: 10px !important;
  margin: 0 0 20px 0;
  border-radius: 10px !important;
  right: 7px !important;
  top: 10px !important;

  .ant-dropdown-menu-item {
    padding: 2px 12px !important;
  }

  .welcomeWrapper {
    li {
      &:hover {
        background: none !important;
      }
    }
  }

  .dropDownhrline,
  .dropDownhrlineBottom {
    border-top: 1px solid #58697340;
  }

  .dropDownhrline {
    margin: 5px 0;
  }

  .dropDownhrlineBottom {
    margin: 5px 10px 0 10px;
  }

  .ant-dropdown-menu-title-content {
    svg {
      position: relative;
      top: 3px;
    }
  }

  .dropDown-text {
    margin-left: 10px;
    position: relative;
    bottom: 2px;
  }
}

.arrow-up {
  width: 0px;
  height: 0px;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  position: absolute;
  top: -16px;
  right: 106px;
  border-bottom: 10px solid white;
}

.profile_CompleteName {
  display: flex;
  align-items: center;
}

.NameAndFoundation {
  min-width: 100px;
  text-align: left;
  max-height: 75px !important;
  margin-left: 20px;
}

p.completeName {
  font-size: 14px;
  text-transform: capitalize;
  color: #122640 !important;
}

span.designation {
  font-size: 12px;
  text-transform: capitalize;
  color: #586973 !important;
  position: absolute;
  bottom: 0;
  top: 20px;
}

.DownOutIcon {
  margin-left: 10px;

  path {
    fill: #586973;
  }
}

.profile-dropdown-heading {
  font-size: 12px;
  font-weight: bold;
  color: var(--title-color);
  text-transform: capitalize;
}

#components-layout-demo-fixed .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout .site-layout-background {
  background: #fff;
}

.navbar__item {
  padding: 0 17px !important;
}

.navbar {
  .navbar_icons {
    height: 50px !important;
  }
}

.navbar__title {
  margin-right: auto;
  font-size: 150%;
  padding: 12px 16px;
  position: relative;
  margin-left: 10px;
}

.vr_line {
  border-right: 1px solid #c9ccce;
  height: 35px;
  margin: auto;
  margin-top: 7px;
}

.user-details {
  border: 1px solid red;
  margin-left: 45px;
}

.navbar__item {
  padding: 16px 16px;
  cursor: pointer;
  vertical-align: middle;
}

.dropDownicon {
  display: flex;

  .dropDownicon {
    padding-left: 20px;
  }
}

.profile-dropdown-items {
  font-size: 14px;
  color: var(--pg-heading-color);
}

.ant-dropdown {
  position: fixed !important;
}

.ant-popover {
  top: 50px !important;

  .ant-popover-content {
    .ant-popover-arrow {
      .ant-popover-arrow-content {
        &::before {
          background: black !important;
        }
      }
    }

    .ant-popover-inner {
      background-color: black !important;
      border-radius: 4px !important;
      box-shadow: none !important;

      .ant-popover-title {
        min-width: 90px !important;
        min-height: 0px !important;
        color: white !important;
      }
      .ant-popover-inner-content {
        display: none !important;
      }
    }
  }
}

@media (max-width: 1600px) {
  .navbar {
    .navbar_icons {
      svg {
        width: 20px !important;
      }
    }

    .toggle-navbar {
      width: 17px;
    }

    .company-logo {
      img {
        width: 150px !important;
      }
    }

    .profile_CompleteName {
      img {
        width: 35px;
      }

      .completeName {
        font-size: 12px;
        text-transform: capitalize;
      }

      .designation {
        font-size: 10px;
      }
    }
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;