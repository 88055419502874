.table-container {
  row-gap: 0px;
  margin: 28px 0 20px 0;
  padding: 20px;
  background-color: var(--card-color);
  border-radius: 10px;
  box-shadow: 0px 1px 3px var(--Complementary-color);

  .tableSearchBar {
    padding-bottom: 10px;

    div {
      gap: 15px !important;
    }

    .ant-space-item {
      .ant-select-selector {
        border-radius: 5px;
      }
    }

    input::placeholder {
      color: var(--title-color) !important;
    }
  }

  .align-right {
    text-align: right;
  }

  .tbl-top-btn {
    color: var(--active-tab-color);
    border: 1px solid var(--active-tab-color);
    border-radius: 5px;
    width: auto;
    height: 40px;

    .table-btn-icons {
      fill: var(--active-tab-color);
      position: relative;
      top: 4px;
    }

    svg {
      margin-right: 9px;
    }
  }

  .ant-table-column-sorter-inner {
    span {
      svg {
        width: 13px !important;
        height: 13px !important;
      }
    }
  }

  .ant-table-column-sorters {
    display: inline-flex;

    .ant-table-column-sorte,
    .ant-table-column-sorter-full {
      margin-left: 15px !important;
    }
  }

  .tbl-top-btn:hover {
    background-color: var(--active-tab-color);
    color: var(--white-color);

    svg path {
      fill: var(--white-color);
    }

    .officeIcon {
      path:last-child {
        fill: var(--active-tab-color);
      }
    }
  }

  .manageRole-icon {
    width: 20px;
    fill: var(--title-color);
  }

  .tbl-btn-icon {
    position: relative;
    top: 3px;
  }

  .ant-table {
    background-color: var(--card-color);
    margin-bottom: 0.6rem;
    .ant-table-cell-row-hover {
      background-color: transparent !important;
    }

    .rowInactiveColor {
      color: var(--inactive-color);
    }

    td.ant-table-column-sort {
      background: none !important;
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
        [colspan]
      )::before {
      display: none;
    }

    thead tr {
      background-color: var(--pale-blue);

      th {
        font-weight: bold;
        font-size: 16px;
      }
    }
    .ant-table-tbody {
      color: var(--list-color);
    }

    tbody tr:nth-child(2n + 2) {
      background: var(--bg-body-color);
    }
    tbody tr:nth-child(odd) {
      background: white;
    }
    .ant-table-placeholder {
      background: white !important;
    }
    tbody tr td {
      font-size: 16px;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 12px 16px !important;
    }
    .ant-table-tbody {
      .ant-table-measure-row {
        td {
          padding-top: 0px !important;
          padding-bottom: 0px !important;
        }
      }
    }

    .ant-table-thead > tr > th {
      background: var(--pale-blue) !important;
      color: var(--header-color);
    }

    .ant-table-footer {
      background: #fff !important;
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
  }

  .cutomPaginationStyling {
    color: var(--page-text);
    .customPageSelector {
      width: 99px;
      height: 32px;
      text-align: center;
      color: var(--page-text);
      margin: 0 5px;
    }
  }

  .icon-set {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    // &:hover {
    //   background: #c9ccce;
    // }
  }
}

// .downloadSendReport {
//   .ant-dropdown-menu-item:hover {
//     background-color: none !important;
//   }
// }

.action_DropDown {
  display: flex;
  align-items: center;
}

.dropdownBtn {
  background: none;
  border: none;
  display: flex !important;
  justify-content: center;
  align-items: center;
  // .dropdownBtn-btn-icons{
  //   width: 14px;
  //   height: 14px;
  // }
  span {
    padding-left: 10px;
  }
}

.dropDown_list_text {
  padding-left: 10px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: var(--txt-hover-color);
}

.dropDown_icon {
  fill: var(--title-color);
}

.logicon {
  margin-left: 7px !important;
}

.actionDropDown {
  padding: 10px !important;
  border-radius: 10px !important;
  box-shadow: 0px 1px 3px #00000029 !important;
}

.ant-tag-green,
.ant-tag-volcano,
.ant-tag-red {
  max-width: 50px;
  padding: 3px 10px !important;
  font-size: 16px !important;
  position: absolute;
  bottom: 10px;
  left: 5px;
  text-align: center;
  background: none !important;
  border: none !important;
}

.ant-tag-volcano {
  color: var(--border-color) !important;
}

.ant-table-content {
  padding: 10px 0;
  min-height: 571px;
}

.tableSreach {
  .tbl-search {
    width: 100%;
    height: 42px;
    margin-right: 0px;
    padding: 6px 10px;
    border: 1px solid var(--Gray85-color);
    border-radius: 6px;
  }

  svg {
    position: absolute;
    z-index: 1;
    right: 25px;
    top: 12px;
  }
}

.veicleIcon {
  width: 20px;
  height: 20px;

  path {
    fill: var(--white-color) !important;
  }
}

.passwordUpdateCheck {
  .ant-checkbox-wrapper {
    width: 18px;
    height: 20px;
    margin-bottom: 20px;
  }
}

.text-right {
  text-align: right;
}

.btn-block {
  display: inline-block !important;
  margin-left: 1rem;
}

.OfficeNameInfoWarpper {
  display: flex;
}

.officeInfo {
  width: 100%;
  max-width: 60%;
  height: auto;

  .comapnyName {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 24px /
      var(--unnamed-line-spacing-25) var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-00080d);
    text-align: left;
    font: normal normal normal 24px/25px Roboto;
    letter-spacing: 0px;
    color: var(--logo-text-color);
    text-transform: capitalize;
    height: 5px;
  }

  .coFounderName {
    color: var(--unnamed-color-00080d);
    font-size: 15px;
    font-weight: 500;

    span {
      color: var(--content-title-color);
      font: italic normal normal 16px/25px Roboto;
    }
  }

  .usdot {
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--logo-text-color);
    text-align: left;
    height: 10px;
    font: normal normal normal 14px/25px Roboto;
    text-transform: capitalize;
  }
}

.OfficeTableHeading {
  position: absolute;
  top: 10px;
  font-size: 17px;
  font-weight: 900;
}

.editWrapper {
  max-height: 20px;
  color: var(--logo-text-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    padding-left: 10px;
    font-weight: 500;
  }

  .editIcon {
    color: var(--content-title-color) !important;
  }
}
.disabledEdit {
  pointer-events: none;
  opacity: 0.4;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-bottom: 1px solid var(--wrapper-color) !important;
    cursor: pointer;
  }
}
.companyContactInfo {
  width: 100%;

  .hrline {
    margin-top: 50px;
    margin-left: 12px;
    border-bottom: 1px solid var(--hrLine-color);
  }

  .contactIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .iconWarpper {
      display: flex;
      align-items: center;
      margin: 10px 0 0 10px;
      word-break: break-all;

      svg path {
        fill: var(--icon-color);
      }

      .contactIconText {
        margin-left: 10px;
        color: var(--logo-text-color);
        text-transform: capitalize;
      }
    }
  }
}

.ant-tooltip-inner {
  border-radius: 6px !important;
}

.ant-select-arrow {
  color: var(--form-label-color) !important;
}

.searchBar {
  border-radius: 5px !important;
  background: var(--bg-body-color) 0% 0% no-repeat padding-box !important;

  input {
    background: var(--bg-body-color) 0% 0% no-repeat padding-box !important;
  }
}

.officeTableTitle {
  padding-top: 10px;

  span {
    text-align: left;
    font: normal normal bold 18px/22px Roboto;
    letter-spacing: 0px;
    color: var(--txt-hover-color);
  }
}

.ant-pagination-item-active {
  background: var(--bg-body-color) !important;
  border-color: var(--line-color) !important;
}

.ant-table-column-sorter {
  display: none;
}

.ant-table-column-sorters {
  width: 100% !important;
  .ant-table-column-title {
    width: 100% !important;

    div {
      width: 100%;

      span {
        padding-left: 10px;
      }
    }
  }
}

.voilationsText {
  color: var(--error-message-field);
  font-size: 16px;
  padding-right: 15px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--white-color) !important;
}

.ant-pagination {
  .ant-pagination-prev {
    .ant-pagination-item-link {
      border-radius: 4px;
      padding: 0 10px;
      &::after {
        content: 'Previous';
        font-size: 14px;
      }
    }
    .anticon-left {
      display: none;
    }
  }

  .ant-pagination-next {
    .ant-pagination-item-link {
      border-radius: 4px;
      padding: 0 10px;
      &::after {
        content: 'Next';
        font-size: 14px;
      }
    }
    .anticon-right {
      display: none;
    }
  }
}
.downloadSendReport {
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }
  .ant-dropdown-menu-item:hover {
    background: none !important;
  }
}
.ant-dropdown-menu-item {
  &:hover {
    background-color: var(--dropdown-color) !important;
  }
}
.ant-dropdown-menu-item-active {
  background: none !important;
}

@media (max-width: 1600px) {
  .table-container {
    .ant-table {
      .ant-table-thead thead tr {
        th {
          font-size: 14px;
        }
      }

      tbody tr td {
        font-size: 14px;
      }

      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td,
      .ant-table tfoot > tr > th,
      .ant-table tfoot > tr > td {
        padding: 9px 7px !important;
      }
    }
    .ant-table-content {
      min-height: 480px;
    }
  }

  .ant-tag-green,
  .ant-tag-volcano {
    padding: 2px 5px !important;
    font-size: 13px !important;
    width: 80px !important;
    height: 25px !important;
  }

  .Icon {
    width: 18px !important;
    height: 18px !important;
  }

  .contactIconText {
    font-size: 12px !important;
  }

  .iconWarpper {
    .Icon {
      width: 18px !important;
      height: 18px !important;
    }

    .contactIconText {
      font-size: 12px !important;
    }
  }
}

@media (max-width: 1400px) {
  .editWrapper {
    left: 92%;
  }
}
