.LogsEventListing {
  background: none !important;
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;

  .ant-table-content {
    min-height: fit-content !important;
  }
}
// .ant-table-thead > tr > th {
//   position: sticky !important;
//   top: -15px !important;
//   z-index: 1 !important;
// }
// .ant-table-content {
//   height: 500px;
//   overflow-y: auto;
//   overflow-x: hidden;
// }
.statusTag {
  border-radius: 10px;
  width: 45px;
  text-align: center;
}
.statusTagText {
  padding-left: 2px;
  padding-top: 2px;
}
.copy-clipboard {
  margin-left: 5px;
  font-size: 16px;
  cursor: pointer;
  color: #586973;
}
.voilationTooltip {
  position: relative;
  display: inline-block;
  top: 2px;

  svg {
    color: #586973;
  }

  .tooltiptext {
    visibility: hidden;
    min-width: 220px;
    // width: 100%;
    height: auto;
    background: #f8d7da 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000040;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 134%;
    margin-left: -30px;

    .tooltipUL {
      list-style: none;
      text-align: left;
      position: absolute;
      left: -28px;

      .vehicleAPIData {
        padding-left: 20px;
      }
    }

    .CustomtooltipText {
      // .AssignedVehilce {
      //   display: flex;
      //   text-align: left;
      //   font: 16px;
      //   letter-spacing: 0px;
      //   color: #f2163e;
      //   opacity: 1;
      // }
      .violation {
        color: #f2163e;
      }
    }
  }
  .arrowDown {
    visibility: hidden;
    z-index: 999;
    width: 0px;
    height: 0px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    position: absolute;
    top: -10px;
    right: -3px;
    border-top: 10px solid #f8d7da;
    box-shadow: inherit;
  }
  &:hover {
    .tooltiptext,
    .arrowDown {
      visibility: visible;
    }
  }
}

.annotationTooltip {
  cursor: pointer;
  position: relative;
  display: inline-block;
  top: 2px;

  svg {
    color: #586973;
  }

  .tooltiptext {
    visibility: hidden;
    min-width: 50px;
    width: auto;
    height: auto;
    background: #586973;
    box-shadow: 0px 0px 6px #00000040;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 134%;
    margin-left: -30px;

    .tooltipUL {
      list-style: none;
      text-align: left;
      position: absolute;
      left: -28px;

      .vehicleAPIData {
        padding-left: 20px;
      }
    }

    .CustomtooltipText {
      .violation {
        color: white;
      }
    }
  }
  .arrowDown {
    visibility: hidden;
    z-index: 999;
    width: 0px;
    height: 0px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    position: absolute;
    top: -10px;
    right: -3px;
    border-top: 10px solid #586973;
    box-shadow: inherit;
  }
  &:hover {
    .tooltiptext,
    .arrowDown {
      visibility: visible;
    }
  }
}

.editedLogHighlight {
  background: #fec3014d !important;
}
.danger-btn-md {
  background: rgba(182, 26, 26);
  border: 1px solid var(--custom-color-blue);
  color: #fff;
  outline: none;
  min-width: 240px;
  min-height: 50px;
  height: 100%;
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'roboto';
}
.danger-btn-md:hover,
.danger-btn-md:active {
  background: rgba(182, 26, 26, 0.904);
  border: 1px solid var(--custom-color-blue);
  color: #fff;
  outline: none;
  min-width: 240px;
  min-height: 50px;
  height: 100%;
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'roboto';
}

// .deviceTooltip {
//   display: flex;
//   align-items: center;
//   .tooltiptext {
//     visibility: hidden;
//     min-width: 149px;
//     height: auto;
//     background: #ffffff 0% 0% no-repeat padding-box;
//     box-shadow: 0px 0px 6px #00000040;
//     text-align: center;
//     border-radius: 6px;
//     padding: 10px;
//     position: absolute;
//     z-index: 1;
//     bottom: 83%;
//     margin-left: -50px;
//     .CustomtooltipText {
//       .text {
//         display: flex;
//         justify-content: center;
//         color: rgb(143, 143, 163);
//         font-size: 14px;
//       }
//     }
//   }
//   &:hover {
//     .tooltiptext,
//     .arrowDown {
//       visibility: visible;
//     }
//   }
// }

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;