@import './styles/base.less';

.App {
  background-color: var(--bg-body-color);
  transition: all 0.5s;
  height: 100vh;
  .pg-header {
    background-color: honeydew;
  }
}
// general
body {
  margin: 0;
  font-family: var(--my-font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.d-flex {
  display: flex !important;
  width: 100%;
  justify-content: flex-end;
  padding-right: 20px;
}

.p40 {
  padding: 40px;
}

.mb60 {
  margin-bottom: 60px;
}

.text-center {
  text-align: center;
}

.w100 {
  width: 100%;
  height: 100%;
}

.w70 {
  width: 70%;
}

.pointer {
  cursor: pointer;
}

//loader
.loader {
  position: absolute;
  top: 5%;
  left: 45%;
  height: 100px;

  img {
    width: 100%;
    max-width: 20%;
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;