.breadCrumb {
  .breadcrumbItem {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    a {
      font-size: 14px;
      font-weight: normal;
      text-transform: capitalize;
      cursor: pointer;
    }
  }
  .breadcrumb_line {
    width: 1760px;
    margin: 15px 0 0 0 !important;
  }
  .hr-line {
    border-top: 1px solid #58697340 !important;
    margin-top: 10px !important;
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;