.timepicker {
  width: 100%;
  height: 50px;
  border-radius: 5px;

  .ant-input-prefix {
    border-right: unset !important;
  }
  input {
    margin-left: 20px !important;
    padding-top: 5px !important;
  }
}
.rdtCount {
  padding-bottom: 25px !important;
}
.rdtCounter .rdtBtn:nth-child(1) {
  height: 30%;
  width: 40px;
  content: url(./chevron-up.svg) !important;
}
.rdtCounter .rdtBtn:last-child {
  height: 30%;
  width: 40px;
  content: url(./chevron-down.svg) !important;
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;