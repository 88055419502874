.loglistingdownload {
  cursor: pointer;
}
.ant-checkbox-inner {
  border-radius: 4px !important;
}

.ant-picker-range {
  input {
    font-size: 16px !important;
  }
  .ant-picker-clear {
    right: 35px !important;
  }
}

.logsRangePicker {
  .ant-space-item {
    .ant-picker-range {
      gap: 0 !important;
      max-width: 290px;
      width: 100%;
    }
  }
}
.dateLogs {
  .dateLogsSelectors {
    display: flex;
    align-items: center;
  }
  .dateLogsSelectors,
  .EditLogs {
    margin: 0;
  }

  .editSectionBtn {
    border: 1px solid #c9ccce;
    border-radius: 4px 0px 0px 4px;
  }

  .currentDay-log {
    background: #e2e7ee;
    border: none;
    color: #122640;
    font: normal normal bold 14px/17px Roboto;
    height: 40px;
    width: 93px;
    border-radius: unset;
    border-top: 1px solid #c9ccce;
    border-bottom: 1px solid #c9ccce;
    box-shadow: unset !important;

    .ant-picker-input {
      input {
        font-weight: bold;
      }
    }
  }

  .tbl-top-btn {
    color: #586973;
    border: 1px solid #c9ccce;
    border-radius: 5px;

    .table-btn-icons {
      fill: #586973;
      position: relative;
      top: 2px;
    }

    svg {
      margin-right: 9px;
    }

    .nextDayIcon {
      margin-left: 9px !important;
    }
  }

  .daySelectorBtnHandle {
    width: 92px !important;

    &:hover {
      background-color: #f0f0f5;
      color: #122640;
    }

    .prevDay {
      position: relative;
      right: 4px;
    }
  }
}
.InspectionTooltip {
  position: relative;
  display: inline-block;
  // top: 2px;

  svg {
    color: #586973;
  }

  .tooltiptext {
    visibility: hidden;
    width: max-content;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000040;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 100;
    bottom: 134%;
    margin-left: -160px;

    .tooltipUL {
      list-style: none;
      text-align: left;
      position: absolute;
      left: -28px;

      .vehicleAPIData {
        padding-left: 20px;
      }
    }

    .CustomtooltipText {
      .AssignedVehilce {
        display: flex;
        text-align: left;
        font: 16px;
        letter-spacing: 0px;
        color: #f2163e;
        opacity: 1;
      }
    }
  }

  .custom-position {
    font-size: 12px !important;
    bottom: 40% !important;
    padding: 5px !important;
    margin-left: 5px !important;
  }
  .arrowDown {
    visibility: hidden;
    z-index: 999;
    width: 0px;
    height: 0px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    position: absolute;
    top: -10px;
    right: -11px;
    border-top: 10px solid white;
    box-shadow: inherit;
  }
  &:hover {
    .tooltiptext,
    .arrowDown {
      visibility: visible;
    }
  }
}
.daySelectorBtnHandle:hover {
  &:hover {
    svg {
      path {
        fill: #122640 !important;
      }
    }
  }
}

.space-between {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;