.info-log-modal{
  top: 35px !important;
}
.insert-info-card-large {
  margin: 0 20px 0 20px !important;
  p.justify-text {
    font-size: 14px;
    text-align: justify;
    font-weight: bold;
    padding-left: 85px;
    padding-right: 85px;
    // text-indent: 50px;
  }

  .adjust-lat-lng-location {
    align-items: center;

    .lat-lng-location-button {
      margin-top: 10px;
      text-align: start;

      .generateLatLngLocation {
        width: 100%;
        height: 50px;
        text-align: center;
        padding: 0 !important;
        // margin-top: 5px;
      }

      .generatepasswordSpace {
        padding-left: 5px;
      }

      .responsiveScreens {
        padding-left: 5px;
      }
    }
  }

}
@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;