.EditLogStatus {
  width: 100%;
  margin-inline: auto;
  max-width: 1250px;
  // padding: 50px 175px;
  .statusHeading {
    text-transform: capitalize !important;
  }
  .content_wrapper button.primary-btn-md:hover {
    border: 1px solid var(--custom-color-blue);
    background: var(--custom-color-blue);
  }
  .confirm {
    min-width: 240px !important;
    min-height: 50px !important;
    width: auto !important;
    margin-left: 10px;
    border: 1px solid #143d73;
    background: #2b5f8c 0% 0% no-repeat padding-box;
    color: #ffff !important;
    border-radius: 5px;

    &:hover {
      border: 1px solid var(--custom-color-blue);
      background: var(--custom-color-blue) no-repeat padding-box;
      color: #ffff !important;
    }
  }
  .canelBtn {
    min-width: 137px !important;
    min-height: 50px !important;
    text-transform: uppercase;
    width: 85px;
    color: var(--title-color);
    background-color: transparent;
    border: 1px solid var(--title-color);
    font-size: 16px;
    padding: 7px 30px;
    height: 100%;
    border-radius: 5px;
  }
  .hr-line {
    margin: 0 !important;
    border-top: 1px solid #58697340 !important;
  }
  .hr-line-bottom {
    margin: 41px 0px 0px !important;
    border-top: 1px solid #58697340 !important;
  }
  .Graph {
    margin-top: 30px;
    .graphContainer {
      padding: 0 40px !important;
    }
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    // border-color: #f0f0f5 !important;
    border-color: unset !important;
  }
  .generateLatLngLocation {
    width: 100%;
    height: 50px !important;
    text-align: center;
    padding: 0 !important;
    margin-top: 32px;
  }
  .dutyStatusList {
    display: flex;
    justify-content: space-between;
    .status {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f0f0f580 0% 0% no-repeat padding-box;
      border: 1px solid #c9ccce;
      border-radius: 4px;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      padding: 24px 0px;
      width: 100%;
      max-width: 300px;
      width: 100%;
      font-size: 16px;
      text-align: center;
      color: #586973;
      text-transform: uppercase;

      .dutyStatusIcon {
        position: relative;
        top: 3px;
        margin-right: 10px;
      }

      svg path {
        fill: #586973 !important;
      }
    }

    .offduty,
    .sleeperberth,
    .driving,
    .onduty {
      margin-right: 20px;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: #ffffff;
    }
    .ant-radio-button-wrapper-checked.offduty {
      .ant-radio-button-checked {
        background: #586973f3 0% 0% no-repeat padding-box;
        border: 1px solid #586973f3;
      }

      svg path {
        fill: #ffff !important;
      }
      .statusText {
        color: #ffff;
      }
    }
    .ant-radio-button-wrapper-checked.sleeperberth {
      .ant-radio-button-checked {
        background: #ff9933f3 0% 0% no-repeat padding-box;
        border: 1px solid #ff9933f3;
      }

      svg path {
        fill: #ffff !important;
      }
      .statusText {
        color: #ffff;
      }
    }
    .ant-radio-button-wrapper-checked.driving {
      .ant-radio-button-checked {
        background: #52c41af3 0% 0% no-repeat padding-box !important;
        border: 1px solid #52c41af3 !important;
      }

      svg path {
        fill: #ffff !important;
      }
      .statusText {
        color: #ffff;
      }
    }
    .ant-radio-button-wrapper-checked.onduty {
      .ant-radio-button-checked {
        background: #2b5f8cf3 0% 0% no-repeat padding-box;
        border: 1px solid #2b5f8cf3;
      }
      svg path {
        fill: #ffff !important;
      }
      .statusText {
        color: #ffff;
      }
    }
    .ant-radio-button-wrapper-checked.ondutyYM {
      .ant-radio-button-checked {
        background: #1ca1bff3 0% 0% no-repeat padding-box;
        border: 1px solid #1ca1bff3;
      }
      svg path {
        fill: #ffff !important;
      }
      .statusText {
        color: #ffff;
      }
    }
    .ant-radio-button-wrapper-checked.offdutyPC {
      .ant-radio-button-checked {
        background: #858e9ef3 0% 0% no-repeat padding-box !important;
        border: 1px solid #858e9ef3;
      }
      svg path {
        fill: #ffff !important;
      }
      .statusText {
        color: #ffff;
      }
    }

    .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled.offduty {
      background: #586973f3 0% 0% no-repeat padding-box;
      border: 1px solid #586973f3;
    }
    .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled.sleeperberth {
      background: #ff9933f3 0% 0% no-repeat padding-box;
      border: 1px solid #ff9933f3;
    }
    .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled.driving {
      background: #52c41af3 0% 0% no-repeat padding-box !important;
      border: 1px solid #52c41af3 !important;
    }
    .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled.ondutyYM {
      background: #1ca1bff3 0% 0% no-repeat padding-box;
      border: 1px solid #1ca1bff3;
    }
    .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled.offdutyPC {
      background: #858e9ef3 0% 0% no-repeat padding-box !important;
      border: 1px solid #858e9ef3;
    }
    .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled.onduty {
      background: #2b5f8cf3 0% 0% no-repeat padding-box;
      border: 1px solid #2b5f8cf3;
    }
  }

  .AddAnnotation {
    padding: 50px 200px !important;
  }
}

@media (max-width: 1599px) {
  .dutyStatusList {
    .status {
      font-size: 15px !important;
    }
  }
}
@media (max-width: 1410px) and (min-width: 1080px) {
  .dutyStatusList {
    .status {
      font-size: 6px !important;
    }
  }
  .dutyStatusIcon {
    height: 6px !important;
    width: 6px !important;
  }
}
@media (max-width: 1710px) and (min-width: 1411px) {
  .dutyStatusList {
    .status {
      font-size: 10px !important;
    }
  }
  .dutyStatusIcon {
    height: 10px !important;
    width: 10px !important;
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;