.logsDetail {
  .singleDayLog {
    .logsInfo {
      list-style: none;
      line-height: 30px;
      position: relative;
      right: 35px;
      top: 7px;

      .infoList {
        display: flex;
        align-items: center;

        .logsInfoSpan {
          font-size: 16px;
          color: #122640;
          margin-left: 10px;
        }
      }

      .violations {
        color: #f2163e !important;
      }
    }

    .splitClockView {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .marginAllClocks {
        margin: 0 5px 0 5px !important;
      }
      .ant-progress-inner {
        width: 111px !important;
        height: 111px !important;
        font-size: 22px !important;
      }
      .progressTitle {
        text-align: center;
        font-size: 9px;
        font-weight: bold;
        color: #586973;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .progressClock {
        font-weight: bold;
        height: 1px;
        color: black;
      }

      .progressDicription {
        font-size: 14px;
        color: #586973;
        text-transform: uppercase;
        font-weight: bold;
      }

      // Animation Cloks

      .hours-progress-show {
        position: relative;
        z-index: 1;

        // SB Clock
        .clock1 {
          .ant-progress-inner {
            background: white;
            width: 73px !important;
            height: 73px !important;
            font-size: 1px;
            top: 63px !important;
            margin-left: 8px;
            z-index: 1;
            position: absolute;

            .ant-progress-text {
              height: 67px;

              .progressTitle {
                margin-top: 12px;
                margin-bottom: 13px;
                height: 0;
              }

              .progressClock {
                font-size: 19px;
                height: 0;
              }

              .progressDicription {
                position: relative;
                bottom: 6px;
              }
            }
          }

          .progressTitle {
            text-align: center;
            font-size: 8px;
            font-weight: bold;
            color: #586973;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          .progressClock {
            font-weight: bold;
            height: 1px;
            color: black;
          }

          .progressDicription {
            font-size: 14px;
            color: #586973;
            text-transform: uppercase;
            font-weight: bold;
          }
        }

        // Large Clocks HOS
        .clock2 {
          border-radius: 50%;

          .ant-progress-inner {
            margin-left: 65px;
            bottom: 8px;
          }

          .ant-progress-inner {
            margin-left: 51px !important;
          }
        }

        .ant-progress-circle.ant-progress-status-success .ant-progress-text {
          color: #00080d !important;
        }
      }

      // .splitclockDrive {
      //   position: absolute;
      //   left: 255px;
      //   bottom: 100px;
      //   .ant-progress-inner {
      //     width: 70px !important;
      //     height: 70px !important;
      //   }
      //   .ant-progress-text {
      //     .progressClock {
      //       font-size: 15px !important;
      //     }
      //   }
      // }
      .cycleClock2 {
        margin-left: 111px;
      }
    }

    .editLogSection {
      display: flex;
      justify-content: flex-end;
      padding-top: 14px;

      .dateSelectors,
      .EditLogs {
        margin: 0;
      }

      .editSectionBtn {
        border: 1px solid #c9ccce;
        border-radius: 4px 0px 0px 4px;
      }

      .currentDay {
        background: #e2e7ee;
        border: none;
        color: #122640;
        font: normal normal bold 14px/17px Roboto;
        height: 34px;
        width: 93px;
        top: -1px;

        .ant-picker-input {
          input {
            font-weight: bold;
          }
        }
      }

      .tbl-top-btn {
        color: #586973;
        border: 1px solid #c9ccce;
        border-radius: 5px;

        .table-btn-icons {
          fill: #586973;
          position: relative;
          top: 2px;
        }

        svg {
          margin-right: 9px;
        }

        .nextDayIcon {
          margin-left: 9px !important;
        }
      }

      .daySelectorBtn {
        width: 92px !important;

        &:hover {
          background-color: #f0f0f5;
        }

        .prevDay {
          position: relative;
          right: 4px;
        }
      }
    }
    .dateSection {
      display: flex;
      justify-content: flex-start;

      .dateSelectors,
      .EditLogs {
        margin: 0;
      }

      .editSectionBtn {
        border: 1px solid #c9ccce;
        border-radius: 4px 0px 0px 4px;
      }

      .currentDay {
        background: #e2e7ee;
        border: none;
        color: #122640;
        font: normal normal bold 14px/17px Roboto;
        height: 34px;
        width: 93px;
        top: -1px;

        .ant-picker-input {
          input {
            font-weight: bold;
          }
        }
      }

      .tbl-top-btn {
        color: #586973;
        border: 1px solid #c9ccce;
        border-radius: 5px;

        .table-btn-icons {
          fill: #586973;
          position: relative;
          top: 2px;
        }

        svg {
          margin-right: 9px;
        }

        .nextDayIcon {
          margin-left: 9px !important;
        }
      }

      .daySelectorBtn {
        width: 92px !important;

        &:hover {
          background-color: #f0f0f5;
        }

        .prevDay {
          position: relative;
          right: 4px;
        }
      }
    }
    .logs-selection-section {
      display: flex;
      justify-content: end;
      padding-top: 15px;
      flex-wrap: wrap;

      .EditLogs .tbl-top-btn,
      .submitBtn {
        color: #586973;
        border: 1px solid #c9ccce;
        border-radius: 5px;
        width: auto;

        .table-btn-icons {
          fill: #586973;
          position: relative;
          top: 2px;
        }

        svg {
          margin-right: 9px;
        }
        .nextDayIcon {
          margin-left: 9px !important;
        }
      }
      .submitBtn {
        //  width: 84px !important;
        width: auto;
        margin-left: 10px;
        border: 1px solid #143d73;
        background: #2b5f8c 0% 0% no-repeat padding-box;
        color: #ffff;
        text-transform: uppercase;
      }
      .canelBtn {
        text-transform: uppercase;
        // width: 84px !important;
        width: auto;

        &:hover {
          color: #00080d;
          border: 1px solid #00080d;
        }
      }

      .actionButton {
        margin: 0 0 7px 7px;

        button {
          min-width: 160.5px;
          max-width: 160.5px;
          width: 100%;
        }
      }
    }
  }

  .approvalEditLog {
    padding: 10px 15px 10px 15px !important;

    border: 1px solid #ff9933;
    background: #fec3014d 0% 0% no-repeat padding-box;

    .approvalTest {
      display: flex;
      align-items: center;

      .approvalHeading {
        padding-top: 2px;
        font-size: 18px;
        font-weight: bold;
        color: #122640;
      }

      .driverName {
        font-size: 16px;
        color: #122640;
      }
    }

    .approvalSubmitSection {
      .submitSection {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .tbl-top-btn,
      .submitBtn {
        color: #586973;
        border: 1px solid #c9ccce;
        border-radius: 5px;
        width: auto;
        min-width: 100px;

        .table-btn-icons {
          fill: #586973;
          position: relative;
          top: 2px;
        }

        svg {
          margin-right: 9px;
        }

        .nextDayIcon {
          margin-left: 9px !important;
        }
      }

      .submitBtn {
        width: 85px;
        // margin-left: 10px;
        // margin-right: 10px;
        border: 1px solid #143d73;
        background: #2b5f8c 0% 0% no-repeat padding-box;
        color: #ffff;
        text-transform: uppercase;
      }
      .sendBtn {
        width: 85px;
        margin-left: 10px;
        border: 1px solid #143d73;
        background: #2b5f8c 0% 0% no-repeat padding-box;
        color: #ffff;
        text-transform: uppercase;
      }
      .canelBtn {
        text-transform: uppercase;
        width: 85px;
        margin-right: 10px;
      }
    }
  }

  .LogEvents {
    padding: 20px !important;

    .pg-heading {
      text-align: left;
      font: normal normal bold 18px/22px Roboto;
      letter-spacing: 0px;
      color: #122640;
      text-transform: capitalize !important;
    }

    .hr-line {
      margin: 0 !important;
      border-top: 1px solid #58697340 !important;
    }

    .noDataContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 200px;

      .ant-empty-description {
        color: #586973;
      }
    }

    .units-card-content {
      .ant-tabs-tab-btn {
        font-size: 18px;
        color: #586973;
        text-transform: uppercase;
      }

      .ant-tabs-tab-active {
        font-weight: bold;
      }
    }
  }

  .Violations {
    .violationList {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0 20px 20px 20px;

      .listheading {
        margin: 10px 20px 0 0;

        span {
          color: #f2163e;
          font-size: 16px;
          margin-left: 10px;
          font-weight: bold;
        }
      }

      .violation {
        border: 1px solid #f5c6cb;
        background-color: #f8d7da;
        padding: 10px 25px;
        text-align: center;
        font-size: 16px;
        color: #f2163e;
        border-radius: 4px;
        margin-right: 20px;
      }
    }
  }

  ul.sub-childs {
    list-style-type: none;
    line-height: 30px;
    padding: 5px 0 0 0;
    margin: 0;

    .emptyField {
      color: #c9ccce;
    }

    li.single-item {
      &:nth-child(even) {
        background: #f0f0f5;
      }

      span {
        padding: 5px 15px;
        display: flex;
        align-items: center;

        &.p-body {
          font: normal normal normal 16px/19px Roboto;
          letter-spacing: 0px;
          color: #122640;
          margin-top: 5px;
        }
      }

      .singleItemCol {
        border-right: 1px solid #58697340;
      }
    }

    .violation {
      background: #f8d7da !important;

      .content-title-bold {
        color: #f2163e !important;
      }

      .violationTag {
        background-color: white;
        float: right;
        margin-top: 5px;
        padding: 0px 7px;
        border-radius: 4px;
        color: #586973;
      }
    }
  }

  li.single-item1 {
    &:nth-child(even) {
      background: #f8d7da 0% 0% no-repeat padding-box;
    }

    span {
      padding: 5px 15px;

      &.p-body {
        font: normal normal normal 16px/19px Roboto;
        letter-spacing: 0px;
        color: #f2163e;
      }
    }

    .singleItemCol {
      border-right: 1px solid #58697340;
    }

    .content-title-bold {
      color: #f2163e !important;
    }
  }

  .DriverSignature {
    .signatureText {
      margin-top: 10%;

      span {
        font-size: 16px;
        color: #122640;
      }
    }

    .driverSignatureIMG {
      max-width: 50px;
      width: 100%;
    }

    .signatureSection {
      display: flex;
      justify-content: flex-end;
      // justify-content: end;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 20px;

      .missingSignature {
        position: relative;
        top: 15px;

        svg {
          margin-left: 13px;
        }

        p {
          font-size: 16px;
          color: #f2163e;
        }
      }
    }

    .ant-table-content {
      min-height: 0 !important;
    }
  }

  .InspectionTooltip {
    .tooltiptext {
      margin-left: -45px;
    }

    .arrowDown {
      top: -14px;
      right: -4px;
    }
  }
}

//FOR SPLIT CLOCK//
// @media screen and (max-width: 1580px) and (min-width: 1380px) {
//   .splitClockView {
//     .breakClock2 {
//     }

//     .breakClock1 {
//     }

//     .hours-progress-show {
//     }

//     .shift-Clock {
//     }

//     .driving-Clock {
//     }

//     .cycleClock {
//     }

//     .recapClock {
//     }
//   }
// }
@media screen and (max-width: 1600px) and (min-width: 1380px) {
  .logsDetail {
    .singleDayLog {
      .logs-selection-section {
        display: flex;
        justify-content: end;
        // padding-left: 100px;
      }
    }
  }
}
@media screen and (max-width: 1379px) and (min-width: 1222px) {
  .logsDetail .singleDayLog .ant-col-11 {
    max-width: 100% !important;
  }
  .splitClockView {
    .responsiveCycleSplit {
      position: absolute;
      top: 140px;
      left: 55px;
    }
    .responsiveRecapSplit {
      position: absolute;
      top: 140px;
      left: 240px;
    }
    .responsiveCycle {
      position: absolute;
      top: 130px;
      left: 110px;
    }
    .responsiveRecap {
      position: absolute;
      top: 130px;
      left: 230px;
    }
  }
  .logsDetail {
    .singleDayLog {
      .logs-selection-section {
        display: flex;
        justify-content: end;
        padding-left: 17px;
      }
    }
  }
}
@media screen and (max-width: 1221px) and (min-width: 1150px) {
  .splitClockView {
    .responsiveCycleSplit {
      position: absolute;
      top: 140px;
      left: 40px;
    }
    .responsiveRecapSplit {
      position: absolute;
      top: 140px;
      left: 210px;
    }
    .responsiveCycle {
      position: absolute;
      top: 130px;
      left: 85px;
    }
    .responsiveRecap {
      position: absolute;
      top: 130px;
      left: 205px;
    }
  }
  .logsDetail {
    .singleDayLog {
      .logs-selection-section {
        display: flex;
        justify-content: end;
        padding-left: 17px;
      }
    }
  }
}
@media screen and (max-width: 1149px) and (min-width: 1050px) {
  .splitClockView {
    .responsiveCycleSplit {
      position: absolute;
      top: 140px;
      left: 15px;
    }
    .responsiveRecapSplit {
      position: absolute;
      top: 140px;
      left: 190px;
    }
    .responsiveCycle {
      position: absolute;
      top: 130px;
      left: 65px;
    }
    .responsiveRecap {
      position: absolute;
      top: 130px;
      left: 185px;
    }
  }
  .logsDetail {
    .singleDayLog {
      .logs-selection-section {
        display: flex;
        justify-content: end;
        // padding-left: 17px;
      }
    }
  }
}
// @media (max-width: 1440px) and (min-width: 1360px) {
//   .splitClockView {
//     .breakClock2 {
//       .ant-progress-inner {
//         width: 111px !important;
//         height: 111px !important;
//         font-size: 22px !important;
//       }
//     }
//     .breakClock1 {
//       margin-left: -62px !important;
//       .ant-progress-inner {
//         width: 111px !important;
//         height: 111px !important;
//         font-size: 22px !important;
//       }
//     }
//     .hours-progress-show {
//       margin-left: -35px;
//       .clock1 {
//         margin-left: -2px !important;
//       }
//       .clock2 {
//         .ant-progress-inner {
//           width: 111px !important;
//           height: 111px !important;
//           font-size: 22px !important;
//         }
//       }
//     }
//     .media {
//       margin-left: 43px !important;
//     }
//     .cycleClock2 {
//       margin-left: 128px !important;
//       .ant-progress-inner {
//         width: 111px !important;
//         height: 111px !important;
//         font-size: 22px !important;
//       }
//     }
//     .shift-Clock {
//       margin-left: 63px !important;
//       .ant-progress-inner {
//         width: 111px !important;
//         height: 111px !important;
//         font-size: 22px !important;
//       }
//     }
//     .driving-Clock {
//       margin-left: 32px !important;
//       .ant-progress-inner {
//         width: 111px !important;
//         height: 111px !important;
//         font-size: 22px !important;
//       }
//     }
//     .cycleClock {
//       margin-left: 97px;
//       .ant-progress-inner {
//         width: 111px !important;
//         height: 111px !important;
//         font-size: 22px !important;
//       }
//     }
//   }
// }
// .splitClockView {
//   .driving-Clock {
//     margin-left: 29px !important;
//   }
//   .shift-Clock {
//     margin-left: 57px !important;
//   }
//   .hours-progress-show {
//     margin-left: 16px;
//   }

//   .media {
//     margin-left: 85px !important;
//   }

//   .cycleClock {
//     margin-left: 84px !important;
//   }
//   .cycleClock2 {
//     margin-left: 162px !important;
//   }
// }

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;