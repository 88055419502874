.floating-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 8%;
    margin: 40px;
    box-shadow: 0 0 64px 0 rgba(0, 0, 0, 0.1);

    h4 {
      margin-bottom: 2vh;
    }
  }
}
.private-layout {
  padding: 0;
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;