.certify-modal-main {
  .ant-checkbox-group {
    line-height: 3;
    padding-top: 10px;
    padding-bottom: 30px;

    .ant-checkbox-wrapper {
      min-width: 184px;
    }
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;