.logFormModal {
  .ant-select-selector .ant-select-selection-search-input {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
  .SpaceDatePicker {
    .ant-picker-large .ant-picker-input > input {
      margin-left: 56px !important;
    }
  }
  .tagsShow {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .custom-tag {
      position: relative;
      padding: 10px 15px;
      border-radius: 10px;
      background: var(--bg-body-color);
      margin: 0 10px 10px 0;
    }
    .remove-custom-tag {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
      background: #fff;
      border-radius: 50%;
      right: -4px;
      top: -4px;
      border: 1px solid var(--border-color);
      cursor: pointer;
      font-size: 10px;
    }
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;