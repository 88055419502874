.footer {
  padding-bottom: 20px !important;
  .footer-content {
    display: flex;
    justify-content: space-between;

    .footer-text {
      font-size: 12px;
      color: var(--logo-text-color);
    }
  }
  .hr-line {
    margin: 15px 0;
    border-top: 1px solid #58697340 !important;
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;