.logform_section {
  .logform_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    p.pg-heading {
      margin: 0;
    }
  }
  .tbl-top-btn {
    color: var(--active-tab-color);
    border: 1px solid var(--active-tab-color);
    border-radius: 5px;
    width: auto;
    height: 40px;

    .table-btn-icons {
      fill: var(--active-tab-color);
      position: relative;
      top: 4px;
    }

    svg {
      margin-right: 9px;
    }
  }
  .tbl-top-btn:hover {
    background-color: var(--active-tab-color);
    color: var(--white-color);

    svg path {
      fill: var(--white-color);
    }

    .officeIcon {
      path:last-child {
        fill: var(--active-tab-color);
      }
    }
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;