.transferModelForm {
  .content-title-bold {
    font-weight: bold;
    font-size: 16px;
    color: var(--content-title-bold-color);
  }

  .ant-form-large .ant-form-item-label > label {
    margin-left: 5px !important;
  }

  .SpaceDatePicker {
    width: 100% !important;

    .ant-picker .ant-picker-large {
      width: 100%;
      height: 50px;
      border-radius: 5px;
    }
  }
}

@primary-color: #586973;@primary-color-hover: #586973;@primary-color-active: #586973;@input-border-color: #C9CCCE;@input-hover-border-color: #586973;@btn-border-style: 1px solid #586973;@select-item-selected-color: #FFFFFF;@select-item-selected-bg: #2B5F8C;